.security__img img{
    width:  50%;
    height:  50%;
}
.security__img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.security{
    margin-top: 1rem;
}

.security h2{
    font-size: 40px;
    align-items: center;
    font-style: italic;
    text-align: center;
}

.security__text{
    width: 80%;
    margin: auto;
    text-align: left;
}

.security__text h3{
    font-weight: bolder;
    font-size: 25px;
    margin-bottom: .5rem;
    line-height: 30px;
}

.security__text h3:nth-last-child(2){
    margin-top: 1.2rem;
}

.security__text p{
    font-size: 15px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 3rem;
}

.security__text {
    margin-top: 3rem;
}