@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body{
    background-color: rgb(255,255,255);
    font-family: 'Manrope', sans-serif;
}

.visibility{
    width: 80%;
    margin: auto;
    margin-top: 6rem;
    text-align: left;
}
.visibility h1{
    color: #40196D;
    margin-bottom: 1rem;
    font-weight: bolder;
}

.visibility p{
    margin-bottom: 4rem;
    font-size: 1rem;
    line-height: 25px;
}