
.contact-section{
  width: 80%;
  margin: auto;
  padding-top: 6%;
  /* background-color: #333; */
  color: #000;
  font-family: pop;
  position: relative;
  overflow: hidden;
}
.contact-section > h2{
  font-size: 29px;
  margin-bottom: 20px;
  position: relative;
  color: #000;
}
.contact-section > h2::after{
  content: " ";
  position: absolute;
  width: 80px;
  height: 4px;
  background-color: #D4455E;
  border-radius: 10px;
  left: 0;
  bottom: -4px;
}
.contact-section > p{
  font-size: 16px;
  max-width: 530px;
  opacity: .8;
  line-height: 26px;
  font-weight: 800;
}
.contact-container{
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 40px;
}
.contact-container .details{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.details .detail{
  text-align: center;
}
.detail .uil{
  font-size: 33px;
}
.detail h3{
  font-size: 16px;
  font-weight: 500;
  margin-top: 3px;
  color: #000;
}
.detail p{
  font-size: 15px;
  opacity: .8;
  color: #000;
}
form{
  display: flex;
  flex-direction: column;
}
form input, form textarea{
  padding: 15px 20px;
  border-radius: 5px;
  /* border: none; */
  border: 1px black solid;
  outline: none;
  margin: 15px 0;
  background-color: rgba(255, 255, 255, 0.895);
}
::placeholder{
  font-size: 15px;
  color: #333;
}
form .input-name{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
}
.contact-btn{
  width: 150px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 5rem;
}
