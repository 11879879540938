*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#blog{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}
.blog-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blog-heading span{
    color: #f33c3c;
}
.blog-heading h3{
    font-size: 2.4rem;
    color: #2b2b2b;
    font-weight: 600;
}

.blog-container{
    display: flex;
    justify-content:center;
    align-items: center;
    margin: 20px 0px;
    flex-wrap: wrap;
}

.blog-box{
   width: 350px;
   background-color: #ffffff;
   border: 1px solid #ececec; 
    margin:  20px;
}

.blog-img{
    width: 100%;
    height: auto;
}

.blog-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.blog-text{
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.blog-text span{
    color: #f33c3c;
    font-size: 0.9rem;
}

.blog-text .blog-title{
    font-size: 1.3rem;
    font-weight: 500;
    color: #272727;
}

.blog-text .blog-title:hover{
    color: #f33c3c;
    transition: all ease 0.3s;
}

.blog-text p{
    color: #9b9b9b;
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px 0;
}