.faq__hero-header{
    background-color: #F0F2F4;
    text-align: center;
    padding: 4rem;
}

.faq__hero-header h1{
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 900;
    margin: 0;
    font-style: italic;
    margin-bottom: 2rem;
    align-items: center;
}

.faq__hero-header p{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 40px;
    align-items: center;
 
}

.faq__list{
    width: 80%;
    margin: auto;
}
.faq__list h2{
    font-size: 23px;
    text-align: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
}

.faq__list-text{
    width: 90%;
}

.faq__list-text h4{
    font-size: 20px;
    line-height: 35px;
    margin-bottom: .5rem;
    font-size: 800;
}

.faq__list-text h4:nth-last-child(2){
    margin-top: 1.3rem;
}

.faq__list-text p{
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 2.5rem;
}

