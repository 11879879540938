.Faithmeet__header {
    display: flex;
    flex-direction: row;
    /* background: linear-gradient(45deg,#fd267a,#ff6036); */
    padding: 4rem 6rem;
    background-color: #fff;
}

.Faithmeet__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.Faithmeet__header-content h1 {
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color: #FD316D;
}

.Faithmeet__header-content p {
    font-family: inherit;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.Faithmeet__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-weight:  bold;
    font-size: 20px;
    line-height: 28px;
    /* background: #fff; */
    /* border: 2px solid #FF4820; */
    background: linear-gradient(45deg,#fd267a,#ff6036);
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Faithmeet__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
}

.Faithmeet__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.Faithmeet__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #FD316D;
    text-align: center;
}

.Faithmeet__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Faithmeet__header-image img {
    /* width: 40%; */
    height: 41rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0px auto; */
}


.gallery-section{
    width: 100%;
    background-color: #EEEEEE;
}

.gallery-section .gallery-wrap{
    padding-top: 10px;
}
.gallery-section .gallery-wrap .gallery-images{
    padding-top: 10px;
}
.gallery-section .gallery-wrap .gallery-images{
   width: 80%;
   margin: 0 auto;
   padding-top: 50px;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 20px;
}

.gallery-section .gallery-wrap .gallery-images .gallery{
    width: 100%;
    position: relative;
}
.gallery-section .gallery-wrap .gallery-images .image-section{
    width: 100%;
}

.gallery-section .gallery-wrap .gallery-images .image-section img{
    width: 100%;
    height: auto;
    padding: 5px;
    display: block;
}

.download-text{
    width: 75%;
    margin: auto;
    align-items: left;
    /* background-color: #EEEEEE; */
}
.download-word{
    background-color: #EEEEEE;
    padding: 3rem;
}
.download-text h2{
    margin-top: 2.5rem;
    margin-bottom: 1.8rem;
}
.download-text p{
    line-height: 35px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: .2rem;
}

.download-text span{
    font-weight: bold;
    cursor: pointer;
}

.download-text p:nth-last-child(1){
    margin-bottom: 3rem;
}


.downloadgrid{
    overflow: hidden;
    margin-top: 0rem;
    margin-bottom: 3rem;
}
.imgs{
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: center;
}
.Faithmeet_appstore{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
}

.Faithmeet_appstore img{
    width: 100px;
    height: 36px;
    margin: 0 5px;
}

@media screen and (max-width: 1050px) {
    .Faithmeet__header {
        flex-direction: column;
    }

    .Faithmeet__header-content {
        margin: 0 0 3rem;
    }
}
@media screen and (max-width: 768px){
    .Faithmeet__header-image img{
        width: 40%;
    }
  
    .detail p{
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 650px) {
    .Faithmeet__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .Faithmeet__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .Faithmeet__header-content__people {
        flex-direction: column;
    }

    .Faithmeet__header-content__people p {
        margin: 0;
    }

    .Faithmeet__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .Faithmeet__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }
    form .input-name{
        display: grid;
        grid-template-columns: none;
    }

    .Faithmeet__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .Faithmeet__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
    .Faithmeet__header-image img{
        width: 100%;
    }
    .Faithmeet__header{
        padding: .5rem 1rem;
    }
    .contact-container .details{
        display: flex;
        flex-direction: column;
    } 
}