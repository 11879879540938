*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.read-more{
    background: #eee;
    height: auto;
}

.news{
    width: 80%;
    margin: auto;
    padding: 6%;
}
.news > h6{
    font-size: 13px;
    color: #D4455E;
    font-weight: bold;
    font-family: inherit;
}
.news h1{
    font-size: 2.3rem;
    line-height: 1.25;
    margin: 5px 0px;
    font-family: "Boing",sans-serif;
    color: #D4455E;
    max-width: 90%;
    opacity: .8;
    font-family: inherit;
}

.news .first-p{
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.read-img{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

p{
    font-size: 15px;
    font-family: 500;
    line-height: 25px;
    color: #000;
}

.p-text{
    margin-top: 2rem;
}