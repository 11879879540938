.hero-section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url('../../assets/hero.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.about-section{
    background-color: #F0F2F4;
}

.hero-text-container>h1{
      color: #fff;
      font-size: 55px;
      font-weight: 80%;
      margin-bottom: 1rem;
      align-items: center;
}

.hero-text-container>h3{
      color: #fff;
      margin-bottom: 2rem;
      font-size: 30px;
     text-align: center;
}

.hero-btn-container .hero-btn-1{
    background: linear-gradient(45deg,#fd267a,#ff6036);
    color: #fff;
    outline: none;
    border: none;
    padding: 8px 10px;
    margin-right: 1rem;
    font-weight: 400;
    border-radius: 24pc;
    transition: all 0.35s ease;
}

.hero-btn-container .hero-btn-2{
    color: #fff;
    outline: none;
    border: none;
    padding: 8px 10px;
    margin-right: 1rem;
    font-weight: 400;
    border-radius: 24pc;
    background-color: #5d5b5d !important;
    transition: all 0.35s ease;
}

.about-img-container > img {
    width: 450px;
}
.about-us-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3rem;
    margin-bottom: 0rem;
}
.about-text-container > h3 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 1rem;
}
.about-text-container > p {
    color: #5d5b5d;
    margin-bottom: 1rem;
    line-height: 30px;
}
.list-text-img> button {
    background: linear-gradient(45deg,#fd267a,#ff6036);
    color: #fff;
    outline: none;
    border: none;
    padding: 8px 10px;
    margin-right: 1rem;
    font-weight: 400;
    border-radius: 24pc;
    transition: all 0.35s ease;
}

.about-team-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 0rem;
    padding: 2rem;
}

.about-team-section > h3 {
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 1rem;
  }
.about-team-section > p {
    color: #5d5b5d;
    margin-bottom: 2rem;
  }
  .team-container {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  @media (max-width:768px){
    .about-us-section{
        display: flex;
        flex-direction: column-reverse;
    }
    .about-text-container > button{
        margin-bottom: 2rem;
    }
  }

@media (max-width: 500px){
    .hero-section{
        background-size: center;
    }
    .about-img-container > img {
        width: 350px;
    }
    .about-text-container{
        padding: 2rem;
    }
    .hero-text-container>h1 {
        color: #fff;
        font-size: 50px;
        text-align: center;
    }
}

@media (max-width: 400px){
    .hero-text-container>h1 {
        color: #fff;
        font-size: 46px;
        text-align: center;
    }
}

@media (max-width: 350px){
    .hero-text-container>h1 {
        color: #fff;
        font-size: 37px;
        text-align: center;
    }
    .about-img-container > img {
        width: 300px;
    }
}
