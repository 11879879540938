
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body{
    background-color: #F0F2F4;
    font-family: 'Manrope', sans-serif;
}


.faithmeet__article {
    display: flex;
    flex-direction: column;    
   
}
.faithmeet__article-home{
    padding: 4rem 6rem;
}
.faithmeet__article-heading {
    width: 100%;
    margin-bottom: 3rem;
    text-align: left;
    background-color: #E8EDFF;
    padding: 4rem;
    border-radius: 12px;
}

.faithmeet__article-heading h6 {
    font-size: .7rem;
    font-weight: 900;
    /* line-height: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #D4455E;

}

.faithmeet__article-Media{
    width: 100%;
    text-align: left;
    margin-bottom: 3rem;
}
.faithmeet__article-Media h2{
    font-size: 3rem;
    font-weight: 900;
    color: #D4455E;
}

.faithmeet__article-heading p:nth-last-child(1){
    color: #D4455E;
    font-size: 25px;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
    align-items: center;
    max-width: 600px;
    margin: 10px auto;

}
.faithmeet__article-container {
    display: flex;
    flex-direction: row;
}

.faithmeet__article-container_groupB {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.3rem;
}

.btn{
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px 26px 11px 26px;
    background: linear-gradient(45deg,#fd267a,#ff6036);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42); 
    cursor: pointer;
    font-weight: bold;
    margin-top: 3.4rem;
}

.one{
    margin-top: 2rem;
}

.btn-Press{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

@media screen and (max-width: 1100px) {
    .faithmeet__article-home {
        padding: 2rem 0rem;
    }
}
@media screen and (max-width: 990px) {
    .faithmeet__article-container {
        flex-direction: column-reverse;
    }
    .faithmeet__article-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }
    .faithmeet__article-home{
        padding: 6rem 4rem;
    }
}

@media screen and (max-width: 700px) {
    .faithmeet__article-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }


    .faithmeet__article-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .faithmeet__article-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}

@media screen and (max-width: 550px) {
    .faithmeet__article-heading p:nth-last-child(1){
        font-size: 18px;
        max-width: 280px;
    }
    .faithmeet__article-heading h6 {
        text-align: center;
    }
}

@media screen and (max-width: 320px) {
    .faithmeet__article-heading p:nth-last-child(1){
        font-size: 13px;
        max-width: 280px;
    }
    .faithmeet__article-heading h6 {
        text-align: center;
    }
}