.services{
    background-color: #F0F2F4;  
    padding: 4rem;
}
.services h2{
    font-size: 2.2rem;
}
.services p{
    font-size: 16px;
    max-width: 750px;
    margin: auto;
    line-height: 30px;
}
.services-img-container > img {
    width: 450px;
}
.services-us-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0rem;
    margin-bottom: 3rem;
    padding: 3rem;
    background: linear-gradient(45deg,#fd267a,#ff6036);
}
.services-text-container > h3 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: .6rem;
    color: #fff;
}
.services-text-container > p {
    color: #fff;
    margin-bottom: 1rem;
    line-height: 30px;
}

.services-text-container a{
    font-size: 17px;
    line-height: 30px;
    color: #fff;
}
.services-text-container ul {
    margin: auto;
    width: 90%;
    margin-bottom: 1rem;
    color: #fff;
}
.services-text-container > button {
    background: linear-gradient(45deg,#fd267a,#ff6036);
    color: #fff;
    outline: none;
    border: none;
    padding: 8px 10px;
    margin-right: 1rem;
    font-weight: 400;
    border-radius: 24pc;
    transition: all 0.35s ease;
}

.services__hero{
    margin-bottom: 3.4rem;
}
.services__hero h1{
    text-align: left;
    font-size: 35px;
    width: 91%;
    margin:auto;
}
.services__border{
    width: 92%;
    margin: auto;
    margin-bottom: 2rem;
}

.services__border h1{
    font-size: 5rem;
    font-weight: bold;
    letter-spacing: -.03em;
    line-height: 5rem;
    margin-bottom: 2rem;
    color: #1D1D1D;
}
.services__border p{
    font-size: 1.4rem;
    line-height: 30px;
    margin-bottom: 2rem;
    color: #1D1D1D;
}

.services__border span{
    color: #EB7DA7;
}
.heading{
    text-align: center;
    font-size: 40px;
    padding: 20px 0;
    color:  #000;
}

.box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 15px;
    padding: 20px;
    width: 95%;
    margin:auto;
}

.services__border ul li a {
    font-size: 18px;
    color: #1D1D1D;
    line-height: 40px;
    font-weight: 500;
}

.box-container .box{
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    border: 2px solid #D4455E;;
    text-align: center;
}

.box-container .box .icon{
    margin-top: 10px
}

.box-container .box .icon span{
    margin:  10px 0;
    font-size: 35px;
    color:  #666;
}

.box-container .box .title{
    color: #666;
    font-size: 20px;
    font-weight: bolder;
}

.box-container .box p{
    font-size: 13px;
    color: #666;
    padding: 10px;
}

.box-container .box a{
    color: #D4455E;
    display: block;
    padding: 10px 0;
    font-size: 17px;
    cursor: pointer;
}


.faithmeet__possibility {
    display: flex;
    flex-direction: row;
    width: 92%;
    margin: auto;
    padding: 4rem 6rem;
}

.faithmeet__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.faithmeet__possibility-image img {
    width: 100%;
    height: 100%;
}

.faithmeet__possibility-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.faithmeet__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.faithmeet__possibility-content h4:last-child {
    color: #ff8A71;
}

.faithmeet__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.faithmeet__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .faithmeet__possibility {
        flex-direction: column;
    }

    .faithmeet__possibility-image {
        margin: 1rem 0;
    }

    .faithmeet__possibility-image img {
        width: unset;
        height: unset;
    }

    .faithmeet__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .faithmeet__possibility-image img {
        width: 100%;
        height: 100%;
    }
}



@media (max-width:991px){
    section{
        padding:2rem;
    }
}

@media (max-width:500px){
    .services-img-container > img {
        width: 350px;
    }
}

@media (max-width:380px){
    .services-img-container > img {
        width: 300px;
    }
}