@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.containerss {
    max-width: 1200px;
    margin: 0 auto;
}

.wrapper {
    text-align: center;
    padding: 60px 20px;
}

.title {
    font-size: 32px;
    color: hsl(233, 13%, 49%);
}


.switch input {
    opacity: 0;
}

.switch {
    display: inline-block;
    width: 60px;
    height: 32px;
    position: relative;
    cursor: pointer;
}

.slider {
    position: absolute;
    background: linear-gradient(45deg,#fd267a,#ff6036);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 35px;
}



.slider::before {
    position: absolute;
    content: '';
    background-color: #FFF;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    transition: .4s;
}

input:checked+.slider::before {
    transform: translateX(26px);
}

.pricing {
    color: hsl(234, 14%, 74%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px 0;
}

.pricing label {
    margin: 0 20px;
}

.pricing-main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.pricing-box {
    background-color: #FFF;
    padding: 50px 40px;
    border-radius: 12px;
    align-self: center;
}

.box-title {
    color: hsl(233, 13%, 49%);
    font-size: 18px;
    margin-bottom: 30px;
}

.price-annually,
.price-monthly {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: hsl(232, 13%, 33%);
}



.price {
    font-size: 65px;
    padding-left: 7px;
}

main {
    list-style: none;
    padding: 35px 0px;
}


main h6 {
    color: hsl(233, 13%, 49%);
    border-bottom: 1px solid hsl(234, 14%, 74%);
    padding: 18px 0;
    font-size: 16px;
}

main h6:first-child {
    border-top: 1px solid hsl(234, 14%, 74%);
}

h5 {
    border: none;
    background: linear-gradient(45deg,#fd267a,#ff6036);
    display: block;
    width: 100%;
    padding: 16px 0;
    border-radius: 7px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    border: 1px solid transparent;
}

h5:hover {
    color: #fd267a;
    background: transparent;
    border-color: ##fd267a;
}

.pricing-box.featured {
    background: linear-gradient(45deg,#fd267a,#ff6036);
    transform: scaleX(1.05);
    padding: 80px 40px;
}

.pricing-box.featured p {
    color: #FFF;
}

.pricing-box.featured main h6 {
    color: #FFF;
    /* border-color: ; */
}

.pricing-box.featured h5 {
    background: #FFF;
    color: #fd267a;
}

.pricing-box.featured h5:hover {
    color: #FFF;
    border-color: #FFF;
    background: transparent;
}

.text-sub{
    width: 80%;
    margin: auto;
}
.text__sub-text h2{
    font-size: 2.6rem;
    font-family: inherit;
    line-height: 30px;
    margin-bottom: 1rem;
}
.text__sub-text p{
    font-size: 14px;
    line-height: 30px;
    font-family: inherit;
    font-weight: 400;
}
.text__sub-text p:nth-last-child(2){
    margin-bottom: 1rem;
}
.text__sub-text p:nth-last-child(3){
    margin-bottom: 1rem;
}
.text__sub-text p:nth-last-child(1){
    margin-bottom: 1rem;
}
@media only screen and (max-width: 1024px) {
    body {
        font-size: 14px;
    }

    .price {
        font-size: 50px;
    }

    .pricing-box {
        padding: 40px 30px;
    }

    .pricing-box.feautred {
        padding: 70px 30px;
    }
}

@media only screen and (max-width: 820px) {
    .pricing-main {
        grid-template-columns: 1fr;
        row-gap: 30px;
    }

    .price {
        font-size: 50px;
    }

    .pricing-box {
        padding: 50px 50px;
    }

    .pricing-box.featured {
        transform: scaleX(1);
        padding: 50px 50px;
    }
}
