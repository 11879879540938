
.change__text{
    background: #EAF6FC;
    padding: 4.1rem;
}

.change__text h1{
    font-size: 2.4rem;
    text-align: center;
    align-items: center;
    margin-bottom: 1rem;
}

.change__text p{
    font-size: 16px;
    text-align: center;
    line-height: 26px;
}

.change__text-hero{
    width: 80%;
    margin: auto;
}
.change__text-hero h3{
    font-size: 21px;
    margin-top: 3rem;
    margin-bottom: .5rem;
}
.change__text-hero p{
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 1rem;
}

.change__text-hero ul li{
    width: 92%;
    margin: auto;
}

.change__text-hero li{
    font-size: 15px;
    line-height: 30px;
}

.change__text-hero {
    margin-bottom: 3rem;
}