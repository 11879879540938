body{
    background: #fcfcfc;
    font-family: sans-serif;
}
footer{
    background: linear-gradient(45deg,#fd267a,#ff6036);
    height: auto;
    padding-top: 40px;
    color: #fff;
}

.buddy__footer-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.bubby__footer-list .list-text{
    
    margin: 0 10px;
}
.bubby__footer-list a{
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
}

.bubby__footer-list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
}
.footer-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.footer-content img{
    width: 100px;
}

.socials{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1.7rem 0;
}
.socials span{
    margin: 0 10px;
}
.socials a{
    text-decoration: none;
    color: #fff;
    border: 1.1px solid white;
    padding: 5px;
    border-radius: 50%;

}
.socials a i{
    font-size: 1.1rem;
    width: 20px;
    transition: color .4s ease;

}


.footer-bottom{
    background: #000;
    padding: 20px;
    padding-bottom: 40px;
    text-align: center;
}
.footer-bottom p{
    float: left;
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
}
.footer-bottom p a{
    color:#D4455E;
    font-size: 16px;
    text-decoration: none;
}
.footer-bottom span{
    text-transform: uppercase;
    opacity: .4;
    font-weight: 200;
}
.footer-menu{
     float: right;

}
.footer-menu ul{
     display: flex;
}
.footer-menu ul li{
    padding-right: 10px;
    display: block;
}
.footer-menu ul li a{
    color: #fff;
    text-decoration: none;
    font-weight: 800;
}
.footer-menu ul li a:hover{
     color: #D4455E;
}
.color-text-a{
    color: #fff;
    font-weight: 800;
    font-size: 13px;
}
.list-text{
    font-size: 10px;
}
@media (max-width:500px) {
.footer-menu ul{
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
 }
 .bubby__footer-list a{
     font-size: 11px;
 }
}

@media (max-width:350px) {
    .bubby__footer-list a{
        font-size: 11px;
        font-weight: bold;
    }
    .bubby__footer-list .list-text {
        margin: 0px 3px;
    }
    .faith__hero-text {
        font-size: 1rem;
        line-height: 28px;
    }
}