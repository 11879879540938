@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.Safety-conter{
    width: 100%;
	margin: auto;
	padding: 6.25rem 0;
}

.Safety-conter h1{
    text-align: center;
    font-size: 2rem;
	font-weight: 600;
	color: #40196D;
	margin-bottom: 0.5rem;
}
.Safety-conter p{
	color: #000;
	margin: 0 auto;
	width: 50%;
	font-size: 1rem;
	line-height: 25px;
    text-align: center;
}

.home{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
}
.home .home-flex h3{
    font-size: 2rem;
	font-weight: 600;
	color: #40196D;
	margin-bottom: 0.5rem;
}

.home-flex{
    margin-top: 3rem;
    margin-left: 3rem;
}

.home .home-flex p{
    color: #000;
	/* margin: 0 auto; */
	width: 100%;
	font-size: 1rem;
	line-height: 25px;
}
.home .flex-img img{
    width: 35rem;
}
.containers{
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;
}
.containers h1{
    padding: 60px 0;
    /* color: #fff; */
    font-size: 25px;
    text-align: center;
}
.pic-img{
    width: 100%;
}
.rows{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.col{
    flex-basis: 50%;
    min-width:  250px;
}

.feature-img{
    width: 83%;
    margin: auto;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}
.small-img-row{
    display: flex;
    background: #efefef;
    margin: 20px 0;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    width: 85%;
}

.small-img{
    position: relative;
}
.small-img img{
    width: 150px;
}

.small-img-row p{
    margin-left: 20px;
    color: #707070;
    line-height: 22px;
    font-size: inherit;
}

.play-btn{
    width: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.small-img .play-btn{
    width: 35px;
}

.videos{
    width: 100%;
}

.video-player{
    width: 80%;
   margin: auto;
   padding-left: 10%;
   margin-bottom: 3rem;
}
video:focus{
    outline: none;
}

/* .close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
} */
@media(max-width: 550px){
    .home{
        display: flex;
        flex-direction: column;
        margin-top: -4rem;
    }
    .Safety-conter p{
        width: 100%;
        margin-bottom: -10rem;
    }
}